import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { removeprofile } from "../redux/userAction";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a
              href="!#"
              className="nav-link"
              onClick={(evt) => evt.preventDefault()}
            >
              <div className="nav-profile-image">
                <img
                  src={require("../../assets/images/faces/face1.jpg")}
                  alt="profile"
                />
                <span className="login-status online"></span>{" "}
                {/* change to offline or busy as needed */}
              </div>
              <div className="nav-profile-text">
                <span className="font-weight-bold mb-2">
                  <Trans>
                    {this.props.profile.user &&
                      this.props.profile.user.username}
                  </Trans>
                </span>
                <span className="text-secondary text-small"></span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li>
          <li
            className={
              this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          {this.props.profile.user && this.props.profile.user.user_roles && (
            <li
              className={
                this.isPathActive("/user") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/user">
                <span className="menu-title">
                  <Trans>Users</Trans>
                </span>
                <i className="mdi mdi-account-multiple menu-icon"></i>
              </Link>
            </li>
          )}
          {this.props.profile.user && this.props.profile.user.brand_roles && (
            <li
              className={
                this.isPathActive("/brand") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/brand">
                <span className="menu-title">
                  <Trans>Brands</Trans>
                </span>
                <i className="mdi mdi-tag menu-icon"></i>
              </Link>
            </li>
          )}
          {this.props.profile.user && this.props.profile.user.category_roles && (
            <li
              className={
                this.isPathActive("/category") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/category">
                <span className="menu-title">
                  <Trans>Categories</Trans>
                </span>
                <i className="mdi mdi-file menu-icon"></i>
              </Link>
            </li>
          )}
          {this.props.profile.user && this.props.profile.user.product_roles && (
            <li
              className={
                this.isPathActive("/product") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/product">
                <span className="menu-title">
                  <Trans>Products</Trans>
                </span>
                <i className="mdi mdi-shopping menu-icon"></i>
              </Link>
            </li>
          )}

          <li
            className={
              this.isPathActive("/order") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/order">
              <span className="menu-title">
                <Trans>Orders</Trans>
              </span>
              <i className="mdi mdi-cart menu-icon"></i>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/logout") ? "nav-item active" : "nav-item"
            }
          >
            <Link
              to={"/login"}
              className="nav-link"
              onClick={(e) => {
                e.preventDefault();
                this.props.logout();
                this.props.history.push("/login");
              }}
            >
              <span className="menu-title">
                <Trans>Sign out</Trans>
              </span>
              <i className="mdi mdi-logout menu-icon"></i>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}
const mapStateToProps = (state) => {
  return {
    profile: state.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(removeprofile()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
