import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useSelector } from "react-redux";
const PrivateRoute = ({
  component: Component,
  permission,
  permission_type,
  ...rest
}) => {
  const user = useSelector((state) => state.userData);
  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          if (localStorage.getItem("admin_token")) {
            if (permission) {
              if (user.user && user.user[permission_type]) {
                return <Component {...props} />;
              }
              return <Redirect to="/dashboard" />;
            }
            return <Component {...props} />;
          }

          return <Redirect to="/login" />;
        }}
      />
    </>
  );
};

export default PrivateRoute;
