import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import React from "react";
const client = new ApolloClient({
  uri: `${process.env.REACT_APP_BACKEND_ADMIN}/test/api/graphql`,
  cache: new InMemoryCache(),
});

const Provider = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Provider;
