class ImperativeCounter extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: "open" });
    this.currentCount = 10;
  }
  update() {
    let template = `
    <style>
    .layout{
        display:inline-block;
        position:relative;
    }
      p {
        position:absolute;
        width:140px;
        top:-100px;
        font-size:15px;
        background-color:black;
        color:white;
        border-radius:10px;
       display:none;
       transition:0.4s ease;
      }
      .popup-alert{
          display:inline-block;
          width:10px;
          height:10px;
          padding:10px;
          cursor:pointer;
      }
      .popup-alert:hover +p{
          display:block;
          padding:10px;
      }
      @media screen and (max-width:756px){
          p{
              top:-90px;
          }
      }

    </style>
    <span class="layout">
    <span class="popup-alert">  &vellip; </span>
 
     <p>${this.getAttribute("text")}</p>
</span>
  `;
    this.shadow.innerHTML = template;
  }
  static get observedAttributes() {
    return ["text"];
  }
  connectedCallback() {
    this.update();
  }
  attributeChangedCallback(name, oldValue, newValue) {
    this.update();
  }
}

window.customElements.define("popup-info", ImperativeCounter);
