import React, { Component, Suspense, lazy } from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import GuestRoute from "./routes/GuestRoute";

import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Login = lazy(() => import("./user-pages/Login"));
const User = lazy(() => import("./user/User"));
const NewUser = lazy(() => import("./user/NewUser"));

const Brand = lazy(() => import("./brand/Brand"));
const UpdateUser = lazy(() => import("./user/UpdateUser"));
const Category = lazy(() => import("./categories/Category"));
const Customer = lazy(() => import("./customer/Customer"));
const Order = lazy(() => import("./order/Order"));
const SingleOrder = lazy(() => import("./order/SingleOrder"));
const Product = lazy(() => import("./product/Product"));
const CreateProduct = lazy(() => import("./product/CreateProduct"));
const EditProduct = lazy(() => import("./product/EditProduct"));
const ForgetPassword = lazy(() => import("./user-pages/ForgetPassword"));
const UploadProduct = lazy(() => import("./product/UploadProdut"));
const ResetPassword = lazy(() => import("./user-pages/ResetPassword"));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute
            path="/brand"
            component={Brand}
            permission={true}
            permission_type={"brand_roles"}
          />
          <PrivateRoute
            path="/category"
            component={Category}
            permission={true}
            permission_type={"category_roles"}
          />
          <PrivateRoute path="/customer" component={Customer} />
          <GuestRoute
            path={"/password/reset/:token"}
            component={ResetPassword}
          />
          <PrivateRoute
            path="/user/edit/:id"
            component={UpdateUser}
            permission={true}
            permission_type={"user_roles"}
          />
          <PrivateRoute
            path="/user/create"
            component={NewUser}
            permission={true}
            permission_type={"user_roles"}
          />
          <PrivateRoute
            path="/user"
            component={User}
            permission={true}
            permission_type={"user_roles"}
          />

          <PrivateRoute path="/order/:id" component={SingleOrder} />
          <PrivateRoute path="/order" component={Order} />
          <PrivateRoute
            path="/product/edit/:id"
            component={EditProduct}
            permission={true}
            permission_type={"product_roles"}
          />
          <PrivateRoute
            path="/product/create"
            component={CreateProduct}
            permission={true}
            permission_type={"product_roles"}
          />
          <PrivateRoute
            path="/product"
            component={Product}
            permission={true}
            permission_type={"product_roles"}
          />

          <GuestRoute path="/login" component={Login} />
          <GuestRoute path="/password/forget" component={ForgetPassword} />

          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
