import axios from "axios";

export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_ERROR = "FETCH_PROFILE_ERROR";
export const LOGOUT_PROFILE = "LOGOUT_PROFILE";

export const changeProfile = (info) => {
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: info,
  };
};
export const getProfile = () => {
  return function (dispatch) {
    axios
      .get(`${process.env.REACT_APP_BACKEND_ADMIN}/api/test/admin/profile`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("admin_token"),
        },
      })
      .then((result) => {
        dispatch(changeProfile(result.data));
      })
      .catch((error) => {
        dispatch(removeprofile());
      });
  };
};

export const removeprofile = () => {
  return {
    type: LOGOUT_PROFILE,
  };
};
