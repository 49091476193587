import {
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_ERROR,
  LOGOUT_PROFILE,
} from "./userAction";

const initState = {
  user: null,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_PROFILE_SUCCESS:
      return {
        user: action.payload,
      };
    case FETCH_PROFILE_ERROR:
      return initState;
    case LOGOUT_PROFILE:
      localStorage.removeItem("admin_token");
      return initState;
    default:
      return state;
  }
};

export default userReducer;
