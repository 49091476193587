import React from "react";
import { Route, Redirect } from "react-router-dom";

const GuestRoute = ({ component: Component, ...rest }) => {
  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          return !localStorage.getItem("admin_token") ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          );
        }}
      />
    </>
  );
};

export default GuestRoute;
