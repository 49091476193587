import React from "react";
import ReactDOM from "react-dom";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import "./web-component";
import "react-toastify/dist/ReactToastify.css";

import * as serviceWorker from "./serviceWorker";
import Provider from "./app/shared/ApolloProvider";
import store, { persistor } from "./app/redux/rootReducer";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { getProfile } from "./app/redux/userAction";

store.dispatch(getProfile());

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Provider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
